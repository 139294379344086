// Taken from certell-web-api
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { sortBy, get } from 'lodash';

const TEACHER_ICON_MAP = {
  'Bell Ringers': '/icons/bell-icon.png',
  'Mini-Lessons': '/icons/mini-lesson-icon.png',
  'Discussion Questions': '/icons/discussion-icon.png',
  Quizzes: '/icons/quiz-icon.png',
  Assignments: '/icons/assignment-icon.png',
  'Document Based Questions': '/icons/document-icon.png',
  'Source Documents': '/icons/lesson-icon.svg',
  Exams: '/icons/exam-icon.png',
  'Classroom Activities': '/icons/classroom-icon.png',
  'Project-Based Learning': '/icons/project-icon.png'
};

const DONOR_ICON_MAP = {
  Financials: '/icons/calculator.jpg',
  Mission: '/icons/target.jpg',
  'IRS Form 990': '/icons/form.jpg',
  Budget: '/icons/pie-chart.jpg'
};

// To add a new style, add a new option to the switch clause, and then create a style funciton to be used with it.
export function contentfulToHtmlOptions(
  isMobileBundle = false,
  isTeacherUserType = false,
  ebookStyle = 'poptential'
) {
  switch (ebookStyle){
    case 'poptential': 
      return poptentialHtmlOptions(ebookStyle, isMobileBundle, isTeacherUserType)
      break;
    case 'softlySpoken':
      return softylSpokenHtmlOptions(ebookStyle, isMobileBundle, isTeacherUserType)
      break;
  }
  
}

function poptentialHtmlOptions(
  ebookStyle,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  return {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const url = node.data.target.fields.file.url;
        const description = node.data.target.fields.description || '';
        const content =
          node.content && node.content[0] && node.content[0].value
            ? node.content[0].value
            : null;
        return `<a class="entry-link" href="${url}" target="_blank"
            ${
              isMobileBundle
                ? `data-remote="https:${url}" data-local="${url}"`
                : ''
            }
                  >
            ${content || description}
            </a>`;
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const type = node.data.target
          ? node.data.target.sys
            ? node.data.target.sys.contentType
              ? node.data.target.sys.contentType.sys
                ? node.data.target.sys.contentType.sys.id
                : ''
              : ''
            : ''
          : '';
        const id = node.data.target.sys.id;
        let content = node.data.target.fields
          ? node.data.target.fields.title
          : '';
        let url = null;
        if (type === 'bellRinger') {
          url = '/bell-ringer?id=' + id;
          return `<a class="entry-link" href="${url}" target="_blank">${content}</a>`;
        } else if (type === 'miniLesson') {
          url = '/mini-lesson?id=' + id;
          return `<a class="entry-link" href="${url}" target="_blank">${content}</a>`;
        } else if (['unit', 'module', 'chapter'].indexOf(type) !== -1) {
          url = '/ebook?chapterUUID=' + id;
          return `<a class="entry-link" href="${url}" target="_self">${node.content[0].value}</a>`;
        } else if (type == 'popup') {
          return `
            <p data-id="${id}-popup" class="popup-open" style="cursor:pointer;display:inline-block;text-decoration:underline;"> ${
            node.content[0].value
          }</p>
            <div class="popup-content" class="" id="${id}-popup">
              <div>
                <p style="margin-top:0;cursor:pointer;" class="popup-close" data-id="${id}-popup">Close</p>
                ${documentToHtmlString(
                  node.data.target.fields.popupContent,
                  contentfulToHtmlOptions(false, false, ebookStyle)
                )}
              </div>
            </div>
          `;
        } else {
          return `<a href="${url}" target="_blank">
            ${content}
            </a>`;
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // const type = node.data.target.sys.contentType.sys.id;
        // const id = node.data.target.sys.id;
        // const title = node.data.target.fields.title;
        let html = '';
        let surveyType = '';
        surveyType = '14I2QtNxDznew5vkt8cJBW';
        const surveyAnswer = '4jsaYgbzwPXxVpqJhgeLH7';
        const nodeType =
          node.data.target &&
          node.data.target.sys &&
          node.data.target.sys.contentType &&
          node.data.target.sys.contentType
            ? node.data.target.sys.contentType.sys.id
            : null;
        // if (type === 'popup') {
        //   return `
        //     <div style="cursor:pointer;display:inline-block;text-decoration:underline;" class="popup-open" data-id="${id}-popup">${documentToHtmlString(node.data.target.fields.displayText)}</div>
        //     <div class="popup-content" class="" id="${id}-popup">
        //       <div>
        //         <p style="margin-top:0;cursor:pointer;" class="popup-close" data-id="${id}-popup">Close</p>
        //         ${documentToHtmlString(node.data.target.fields.popupContent)}
        //       </div>
        //     </div>
        //   `;
        // }
        if (nodeType == 'header') {
          if (node.data.target.fields.headerType == 'unit') {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header unit1">
                <div class="poptential-header__image" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p style="font-family:'Noto Serif JP';font-weight:'bold';font-size:32px;">${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header unit1">
                <div class="poptential-header__image">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p>${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            }
          } else {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
              
                <img class="poptential-logo-header" src="${node.data.target.fields.textAndBorderColor == 'white' ? '/poptential/poptential-logo-white.png' : '/poptential/poptential-logo-black.png'}" alt="Poptential Logo" style="top: -42px;"/>
                <h2 class="poptential-header__chapter" style="color:${node.data.target.fields.textAndBorderColor};"></h2>
                <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            } else {
              return `
              <div class="poptential-header">
                
                <img class="poptential-logo-header" src="${node.data.target.fields.textAndBorderColor == 'white' ? '/poptential/poptential-logo-white.png' : '/poptential/poptential-logo-black.png'}" alt="Poptential Logo" style="top: -42px;"/>
                <h2 class="poptential-header__chapter" style="color:${node.data.target.fields.textAndBorderColor};"></h2>
                <div class="poptential-header__title" style="border:7px solid ${node.data.target.fields.textAndBorderColor}">
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center; padding-top: 10px;">${node.data.target.fields.moduleNumber}</h2>
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:42px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            }
          }
        }

        if (nodeType == 'columnLayout') {
          const body1 = node.data.target.fields.column1;
          const body2 = node.data.target.fields.column2;
          const body3 = node.data.target.fields.column3;
          return `
          <div style="display: inline-block; width: 100%">
          <div class="flex-container">
          <div style="display:${
            body1 ? 'block' : 'none'
          }">${documentToHtmlString(
            body1,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
            )}</div>
            <div style="display:${
              body2 ? 'block' : 'none'
            }">${documentToHtmlString(
              body2,
              contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
              )}</div>
              <div style="display:${
                body3 ? 'block' : 'none'
              }">${documentToHtmlString(
                body3,
                contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
                )}</div>
                </div>
              </div>
          `;
        }

        if (nodeType == 'notification') {
          return `
            <div style="display: none" 
              class="postpossible-notification-tag ${node.data.target.fields.popUp ? "popUpNotification" : ''} ${node.data.target.fields.email ? "emailNotification" : ''} ${node.data.target.fields.textMessage ? "textNotification" : ''}"
              id="${node.data.target.sys.id}"  
              data-title="${node.data.target.fields.title}"
              data-message="${node.data.target.fields.message}"
              data-goal="${node.data.target.fields.goalPage}"
              data-delay="${node.data.target.fields.delay}"
              data-email-template="${node.data.target.fields.emailTemplate}"
            >
            </div>
          `
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (
          !node.data.target ||
          !node.data.target.fields ||
          !node.data.target.fields.file
        ) {
          return '';
        }
        const file = node.data.target.fields.file;
        
        if (file.contentType.startsWith('image')) {
          return `
            <div class='poptential_image_container'>
              <img src="${file.url}" alt="${file.fileName}" />
              ${
                node.data.target.fields.description
                ? `<br><figcaption>${
                  node.data.target.fields.description
                }</figcaption>`
                : ''
              }
            </div>
            `;
            // return `
            // <img src="${file.url}" alt="${file.fileName}" />
            // ${
            //   node.data.target.fields.description
            //     ? `<figcaption>${marked(
            //         node.data.target.fields.description
            //       )}</figcaption>`
            //     : ''
            // }
            // `;
        }
        if (file.contentType.startsWith('audio')) {
          const id = node.data.target.sys.id
          return `
            <audio controls preload="auto" data-asset-id="${
              file.url.split('/')[4]
            }" data-contentful-id="${id}">
                <source src="${file.url}" type="${
            file.contentType
          }" data-remote="https:${file.url}" data-local="${file.url}">
                <source src="https:${file.url}" type="${
            file.contentType
          }" data-fallback="true">
            </audio>`;
        }
        if (file.contentType.startsWith('application/pdf')) {
          const title = node.data.target.fields.title || '';
          const description = node.data.target.fields.description || '';
          return `
            <a href="${file.url}" target="_blank" data-remote="https:${
            file.url
          }" data-local="${file.url}">${
            title || description || 'PDF Document'
          }</a>`;
        }
        if (file.contentType.startsWith('video')) {
          const src = node.data.target.fields.src
            ? node.data.target.fields.src.fields.file.url
            : '';
          const poster = node.data.target.fields.coverImage
            ? node.data.target.fields.coverImage.fields.file.url
            : 'poster';
          const subtitleFiles = node.data.target.fields.subtitleFiles || [];
          const subtitleTracks = subtitleFiles.map((f, index) => {
            return `<track label="${
              f.fields.title
            }" kind="subtitles" srclang="en" src="https:${f.fields.file.url}" ${
              index === 0 ? 'default' : ''
            }>`;
          });

          return `
            <div class="video-container">
              ${
                node.data.target.fields.displayTitle2
                  ? `<p class="video-title">${documentToHtmlString(
                      node.data.target.fields.displayTitle2
                    )}</p>`
                  : ''
              }
                <video width="640" height="360" controls="true" controlsList="nodownload" poster="${poster}" data-asset-id="${
            src.split('/')[4]
          }" data-remote="https:${poster}"  data-local="${poster}" crossorigin=anonymous>
                    <source src="${src}" type="video/mp4" data-remote="https:${src}" data-local="${src}" >
                    <source src="https:${src}" type="video/mp4" data-fallback="true">
                    ${subtitleTracks.join(' ')}
                </video>
            </div>
          `;
        }
        if (file.url) {
          return `
              <a href="${file.url}" target="_blank">${file.fileName}</a>
            `;
        }
        return '';
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let html = '';
        let surveyType = '';
        surveyType = '14I2QtNxDznew5vkt8cJBW';
        const surveyAnswer = '4jsaYgbzwPXxVpqJhgeLH7';
        const nodeType = node.data.target && node.data.target.sys && node.data.target.sys.contentType && node.data.target.sys.contentType
            ? node.data.target.sys.contentType.sys.id : null;
        if (!nodeType) {
          return '';
        }
        if (nodeType === 'infoBox') {
          const body = node.data.target.fields.body;
          const infoBoxType = node.data.target.fields.type;
          const title = node.data.target.fields.customTitle;
          const getTitle = (defaultTitle = infoBoxType) =>
            title ? title : defaultTitle;
          // surveyType = "14I2QtNxDznew5vkt8cJBW";
          // const surveyAnswer = "4jsaYgbzwPXxVpqJhgeLH7";

          if (infoBoxType === 'Takeaway') {
            return `
              <div class="takeaway">
                <h3 class="takeaway-header">${getTitle('Takeaway:')}</h3>
                <div class="takeaway-content">
                  ${documentToHtmlString(
                    body,
                    contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                  )}
                </div>
              </div>
            `;
          }
          if (infoBoxType === 'Lesson Complete') {
            return `
              <div class="finished">
                <h3 class="finished-header">${getTitle()}</h3>
                <div class="finished-content">
                  ${documentToHtmlString(
                    body,
                    contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                  )}
                </div>
              </div>
            `;
          }
          if (infoBoxType === 'Question') {
            return `
              <div class="question">
                <h3 class="question-header">${getTitle(
                  'Are you getting it?'
                )}</h3>
                <div class="question-content question-block">
                  ${documentToHtmlString(
                    body,
                    contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                  )}
                </div>
              </div>
            `;
          }
          return `
          <blockquote>
             ${documentToHtmlString(
               body,
               contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
             )}
          </blockquote>
          `;
        }

        if (nodeType == 'math') {
          return `<div class="formula-entry">${node.data.target.fields.math}</div>`;
        }

        if (nodeType == 'header') {
          if (node.data.target.fields.headerType == 'unit') {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                
                  <img class="poptential-logo-header poptential-logo-header-unit" src="/poptential/poptential-logo-black.png" alt="Poptential Logo" style="top: -42px;"/>
                  <h2 class="poptential-header__chapter" style="color:black; top:-257px;"></h2>
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p style="font-family:'Noto Serif JP';font-weight:'bold';font-size:32px;">${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image">
                  <img class="poptential-logo-header poptential-logo-header-unit" src="/poptential/poptential-logo-black.png" alt="Poptential Logo" style="top: -42px;"/>                  
                  <h2 class="poptential-header__chapter" style="color: black; top:-257px;"></h2>
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p>${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            }
          } else {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                
                <img class="poptential-logo-header" src="${node.data.target.fields.textAndBorderColor == 'white' ? '/poptential/poptential-logo-white.png' : '/poptential/poptential-logo-black.png'}" alt="Poptential Logo" style="top: -42px;"/>
                <h2 class="poptential-header__chapter" style="color:${node.data.target.fields.textAndBorderColor};"></h2>
                <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header">
                
                <img class="poptential-logo-header" src="${node.data.target.fields.textAndBorderColor == 'white' ? '/poptential/poptential-logo-white.png' : '/poptential/poptential-logo-black.png'}" alt="Poptential Logo" style="top: -42px;"/>
                <h2 class="poptential-header__chapter" style="color:${node.data.target.fields.textAndBorderColor};"></h2>
                <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px; text-align: center;">${node.data.target.fields.moduleNumber}</h2>
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px; text-align: center;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            }
          }
        }

        if (nodeType == 'columnLayout') {
          const body1 = node.data.target.fields.column1;
          const body2 = node.data.target.fields.column2;
          const body3 = node.data.target.fields.column3;
          return `
          <div style="display: inline-block; width: 100%;">
            <div class="flex-container">
              <div style="display:${
                body1 ? 'block' : 'none'
              }">${documentToHtmlString(
            body1,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body2 ? 'block' : 'none'
              }">${documentToHtmlString(
            body2,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body3 ? 'block' : 'none'
              }">${documentToHtmlString(
            body3,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
            </div>
          </div>

          `;
        }

        if (nodeType === 'centeredContent') {
          const body = node.data.target.fields.body;
          return `
            <div class="centered-div-block">
                ${documentToHtmlString(
                  body,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                )}
            </div>
            `;
        }

        // This purposefully does not close <div> tag
        if (nodeType === 'gabeTest') {
          return `
            <div style="background-color: lightblue;">Start Blue Div</div>
              <div style="background-color: #333333;">Content div
          `;
        }

        if (nodeType === 'gabeTestEnd') {
          return `
            </div>
          `;
        }

        if (nodeType === 'multipleChoiceQuestion' || nodeType === surveyType) {
          const question = node.data.target.fields.question;
          const answerDataFromContentful = get(node, 'data.target.fields.answers', []);
          const answersSortedIfMCQ = nodeType === 'multipleChoiceQuestion' ? sortBy(answerDataFromContentful, () => Math.random() - 0.5) : answerDataFromContentful;
          const questionId = node.data.target.sys.id;
          const answersElements = answersSortedIfMCQ.map((a) => {
              return `<li>
                          <input type="radio" 
                          name="${questionId}" 
                          ${
                            isMobileBundle
                              ? `data-quiz-choice-correct="${a.fields.isAnswerCorrect}"`
                              : ''
                          } 
                          data-quiz-choiceuuid="${a.sys.id}" 
                          id="${a.sys.id}"
                          class="multiple-choice-quiz-choices" 
                          value="${a.fields.answer}"> 
                          <label for="${a.sys.id}">${a.fields.answer}</label>
                          <div class="multiple-choice-explaination">${
                            a.fields.explanation || ''
                          }</div>
                      </li>`;
            });

          if (nodeType === 'multipleChoiceQuestion') {
            html = `
              <div class="ebook-quiz ebook-multiple-choice-quiz ${node.data.target.fields.isPoll ? 'poll-question' : ''}" data-quizuuid="${questionId}">
                <p class="quizQuestion">${question}</p>
                <ul>
                   ${answersElements.join('\n')} 
                </ul>
                <button class="multiple-choice-submit ebook-quiz-submit">Submit Answer</button>
              </div>
          `;
          }

          if (nodeType === surveyType) {
            html = `
              <div class="ebook-quiz ebook-multiple-choice-quiz" data-quizuuid="${questionId}">
                <p class="quizQuestion">${question}</p>
                <ul>
                   ${answersElements.join('\n')} 
                </ul>
                </div>
              `;
                // <button class="survey-submit ebook-quiz-submit">Submit Answer</button>
          }
        } else if (nodeType === 'shortAnswerQuestion') {
          const question = node.data.target.fields.question;
          const questionId = node.data.target.sys.id;
          const maxScore = node.data.target.fields.maxScore;
          html = `
                <div class="ebook-short-answer-quiz" data-quizuuid="${questionId}">
                ${
                  maxScore
                    ? `<p class="sa-quiz-points" style="font-weight:bold;font-size:1rem;margin-bottom:5px;"><span class="student-score">--</span>/${maxScore} points</p>`
                    : ''
                }
                  <p style="margin-top:0;" class="quizQuestion">${question}</p>
                  <textarea id="shortAnswerAnswer" rows="5"></textarea>
                  <button class="ebook-quiz-submit" id="btnSumitShortAnswer">Submit Answer</button>
                </div>
          `;
        } else if (nodeType === 'video') {
          const src = node.data.target.fields.src
            ? node.data.target.fields.src.fields.file
              ? node.data.target.fields.src.fields.file.url
              : ''
            : '';
          const poster = node.data.target.fields.coverImage
            ? node.data.target.fields.coverImage.fields.file.url
            : 'poster';
          const subtitleFiles = node.data.target.fields.subtitleFiles || [];
          const subtitleTracks = subtitleFiles.map((file, index) => {
            if (file.fields) {
              return `<track label="${
                file.fields.title
              }" kind="subtitles" srclang="en" src="https:${
                file.fields.file.url
              }" ${index === 0 ? 'default' : ''}>`;
            }
          });

          const id = node.data.target.sys.id

          if(node.data.target.fields.description ){
            console.log(node.data.target)
          }

          const videoTitleOptions = {renderNode:{[BLOCKS.PARAGRAPH]: (node) => `<p class="video-title">${node.content[0].value}</p>`}}
          html = `
            <div class="video-container ${node.data.target.fields.description ? 'video-with-description' : ''}">
              ${
                node.data.target.fields.displayTitle2 ? 
                    documentToHtmlString(node.data.target.fields.displayTitle2, videoTitleOptions)
                    : ''
              }
              <div class="internal-video-wrapper">
              <video width="640" height="360" controls="true" controlsList="nodownload" poster="${poster}" data-asset-id="${
                src.split('/')[4]
              }" data-remote="https:${poster}" data-local="${poster}" data-contentful-id="${id}" crossorigin=anonymous>
              <source src="${src}" type="video/mp4" data-remote="https:${src}" data-local="${src}">
              <source src="https:${src}" type="video/mp4" data-fallback="true">
              ${subtitleTracks.join(' ')}
              </video>
              
              ${
                node.data.target.fields.description 
                ? `<div class="video-description" style="width: 640px;">
                  ${documentToHtmlString(node.data.target.fields.description)}
                </div>`
                : ''
              }
              </div>
            </div>
          `;
        } else if (nodeType === 'embeddedvideo') {
          const src = node.data.target.fields.videoLink || '';
          const [site, id] = extractVideoLinkTypes(src);
          html = '';
          if (!site || !id) {
            return html;
          }
          if (site === 'vimeo') {
            html = `
    <iframe title="vimeo-player" src="https://player.vimeo.com/video/${id}" width="640" height="360" frameborder="0" allowfullscreen></iframe>
              `;
          } else if (site === 'youtube') {
            html = `
              <iframe id="ytplayer" type="text/html" width="640" height="360" src="https://www.youtube.com/embed/${id}" frameborder="0"></iframe>
              `;
          }
        } else if (nodeType === 'teacherResourcesBlock') {
          const teacherBlocks = node.data.target.fields.teacherBlocks;
          const showTeachersBlock = isTeacherUserType
            ? `${documentToHtmlString(
                teacherBlocks,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
              )}`
            : '';
          html = `
            <div>
              ${showTeachersBlock}
            </div>
           `;
        } else if (nodeType === 'teacherContent' && isTeacherUserType) {
          const content = node.data.target.fields.content;
          let url;
          if (node.data.target.fields.attachment) {
            url = node.data.target.fields.attachment.fields.file.url;
          }
          let iconSource =
            TEACHER_ICON_MAP[node.data.target.fields.teacherContentType];
          if (!iconSource) {
            iconSource = '/icons/default-icon.svg';
          }
          const subtitleVisible = node.data.target.fields.subtitle
            ? `<div class="teacher-content__header__subtitle">${node.data.target.fields.subtitle}</div>`
            : '';
          const showDownloadButton = url
            ? `
            <div style="display: flex; flex-direction: row; background-color: #007790; align-items: center; border-radius: 4px; width: 150px">
              <a href="${url}" style="padding: 9px; color: white; font-size: 14px; text-decoration: none" download>DOWNLOAD</a>
              <img style="width: 50px; height: 40px; background-color: #B0CFD6; margin: 0; border-radius: 0 4px 4px 0" src="/icons/arrow-circle-down.svg"/>
            </div>`
            : '';
          html = `
            <div class="teacher-content">
              <div class="teacher-content__header">
                <div class="teacher-content__header__title">
                  <img src="${iconSource}"/>
                  <p>${node.data.target.fields.teacherContentType}</p>
                </div>
                ${subtitleVisible}
              </div>
                <hr/>
              <div class="teacher-content__content">
                ${documentToHtmlString(
                  content,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                )}
              </div>
              <div style="padding-left: 10px">
                ${showDownloadButton}
              </div>
            </div>
           `;
        } else if (nodeType === 'donorContent') {
          const content = node.data.target.fields.content;
          let url;
          if (node.data.target.fields.attachment) {
            url = node.data.target.fields.attachment.fields.file.url;
          }
          let iconSource =
            DONOR_ICON_MAP[node.data.target.fields.donorContentType];
          if (!iconSource) {
            iconSource = '/icons/default-icon.svg';
          }
          const subtitleVisible = node.data.target.fields.subtitle
            ? `<div class="teacher-content__header__subtitle">${node.data.target.fields.subtitle}</div>`
            : '';
          const showDownloadButton = url
            ? `
              <div style="display: flex; flex-direction: row; background-color: #007790; align-items: center; border-radius: 4px; width: 150px">
                <a href="${url}" style="padding: 9px; color: white; font-size: 14px; text-decoration: none" download>DOWNLOAD</a>
                <img style="width: 50px; height: 40px; background-color: #B0CFD6; margin: 0; border-radius: 0 4px 4px 0" src="/icons/arrow-circle-down.svg"/>
              </div>`
            : '';
          html = `
              <div class="teacher-content">
                <div class="teacher-content__header">
                  <div class="teacher-content__header__title">
                    <img src="${iconSource}"/>
                    ${node.data.target.fields.donorContentType}
                  </div>
                  ${subtitleVisible}
                </div>
                  <hr/>
                <div class="teacher-content__content">
                  ${documentToHtmlString(
                    content,
                    contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
                  )}
                </div>
                <div style="padding-left: 10px">
                  ${showDownloadButton}
                </div>
              </div>
             `;
        } else if (nodeType === 'miniLesson') {
          const blockBody = node.data.target.fields.body;
          return ` 
            <div>
              ${documentToHtmlString(
                blockBody,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, false, ebookStyle)
              )}
            </div>
           `;
        } else if (nodeType === 'bellRinger') {
          const blockBody = node.data.target.fields.body;
          return ` 
            <div>
              ${documentToHtmlString(
                blockBody,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, false, ebookStyle)
              )}
            </div>
           `;
        }
        return html;
      },
      [BLOCKS.QUOTE]: (node) => {
        const content = node.content
          ? node.content.map((n) => {
                return documentToHtmlString(n,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                );
              })
              .join('\n')
          : '';
        return `
          <blockquote>
            <pre>
              ${`\n${content}`}
            </pre>
          </blockquote>
        `;
      }
    }
  };
}

function softylSpokenHtmlOptions(
  ebookStyle,
  isMobileBundle = false,
  isTeacherUserType = false
) {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => `<div class="soft-paragraph">${next(node.content)}</div>`,
      [BLOCKS.HEADING_1]: (node, next) => `<h1 class="soft-heading-1 soft-heading">${next(node.content)}</h1>`,
      [BLOCKS.HEADING_2]: (node, next) => `<h2 class="soft-heading-2 soft-heading">${next(node.content)}</h2>`,
      [BLOCKS.HEADING_3]: (node, next) => `<h3 class="soft-heading-3 soft-heading">${next(node.content)}</h3>`,
      [BLOCKS.HEADING_4]: (node, next) => `<h4 class="soft-heading-4 soft-heading">${next(node.content)}</h4>`,
      [BLOCKS.HEADING_5]: (node, next) => `<h5 class="soft-heading-5 soft-heading">${next(node.content)}</h5>`,
      [BLOCKS.HEADING_6]: (node, next) => `<h6 class="soft-heading-6 soft-heading">${next(node.content)}</h6>`,
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const url = node.data.target.fields.file.url;
        const description = node.data.target.fields.description || '';
        const content =
          node.content && node.content[0] && node.content[0].value
            ? node.content[0].value
            : null;
        return `<a href="${url}" target="_blank"
            ${
              isMobileBundle
                ? `data-remote="https:${url}" data-local="${url}"`
                : ''
            }
                  >
            ${content || description}
            </a>`;
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const type = node.data.target
          ? node.data.target.sys
            ? node.data.target.sys.contentType
              ? node.data.target.sys.contentType.sys
                ? node.data.target.sys.contentType.sys.id
                : ''
              : ''
            : ''
          : '';
        const id = node.data.target.sys.id;
        let content = node.data.target.fields
          ? node.data.target.fields.title
          : '';
        let url = null;
        if (type === 'bellRinger') {
          url = '/bell-ringer?id=' + id;
          return `<a class="entry-link" href="${url}" target="_blank">${content}</a>`;
        } else if (type === 'miniLesson') {
          url = '/mini-lesson?id=' + id;
          return `<a class="entry-link" href="${url}" target="_blank">${content}</a>`;
        } else if (['unit', 'module', 'chapter'].indexOf(type) !== -1) {
          url = '/ebook?chapterUUID=' + id;
          return `<a class="entry-link" href="${url}" target="_self">${node.content[0].value}</a>`;
        } else if (type == 'popup') {
          return `
            <p data-id="${id}-popup" class="popup-open" style="cursor:pointer;display:inline-block;text-decoration:underline;"> ${
            node.content[0].value
          }</p>
            <div class="popup-content" class="" id="${id}-popup">
              <div>
                <p style="margin-top:0;cursor:pointer;" class="popup-close" data-id="${id}-popup">Close</p>
                ${documentToHtmlString(
                  node.data.target.fields.popupContent,
                  contentfulToHtmlOptions(false, false, ebookStyle)
                )}
              </div>
            </div>
          `;
        } else {
          return `<a href="${url}" target="_blank">
            ${content}
            </a>`;
        }
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // const type = node.data.target.sys.contentType.sys.id;
        // const id = node.data.target.sys.id;
        // const title = node.data.target.fields.title;
        let html = '';
        let surveyType = '';
        surveyType = '14I2QtNxDznew5vkt8cJBW';
        const surveyAnswer = '4jsaYgbzwPXxVpqJhgeLH7';
        const nodeType =
          node.data.target &&
          node.data.target.sys &&
          node.data.target.sys.contentType &&
          node.data.target.sys.contentType
            ? node.data.target.sys.contentType.sys.id
            : null;
        // if (type === 'popup') {
        //   return `
        //     <div style="cursor:pointer;display:inline-block;text-decoration:underline;" class="popup-open" data-id="${id}-popup">${documentToHtmlString(node.data.target.fields.displayText)}</div>
        //     <div class="popup-content" class="" id="${id}-popup">
        //       <div>
        //         <p style="margin-top:0;cursor:pointer;" class="popup-close" data-id="${id}-popup">Close</p>
        //         ${documentToHtmlString(node.data.target.fields.popupContent)}
        //       </div>
        //     </div>
        //   `;
        // }
        if (nodeType == 'header') {
          if (node.data.target.fields.headerType == 'unit') {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p style="font-family:'Noto Serif JP';font-weight:'bold';font-size:32px;">${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p>${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            }
          } else {
            if (node.data.target.fields.headerImage) {
              return `<div class="soft-spoken-header" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                <div class="soft-spoken-header__title" ${node.data.target.fields.textAndBorderColor}">
                  <h2>${node.data.target.fields.moduleNumber}</h2>
                  <h2>${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            } else {
              return `<div class="soft-spoken-header">
                <div class="soft-spoken-header__title" ${node.data.target.fields.textAndBorderColor}">
                <h2>${node.data.target.fields.moduleNumber}</h2>
                <h2>${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            }
          }
        }

        if (nodeType == 'columnLayout') {
          const body1 = node.data.target.fields.column1;
          const body2 = node.data.target.fields.column2;
          const body3 = node.data.target.fields.column3;
          return `
            <div class="flex-container">
              <div style="display:${
                body1 ? 'block' : 'none'
              }">${documentToHtmlString(
            body1,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body2 ? 'block' : 'none'
              }">${documentToHtmlString(
            body2,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body3 ? 'block' : 'none'
              }">${documentToHtmlString(
            body3,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
            </div>
          `;
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (
          !node.data.target ||
          !node.data.target.fields ||
          !node.data.target.fields.file
        ) {
          return '';
        }
        const file = node.data.target.fields.file;
        
        if (file.contentType.startsWith('image')) {
          return `
            <div class="soft-image-container">
              <img class="soft-image" src="${file.url}" alt="${file.fileName}" />
              ${
                node.data.target.fields.description
                ? `<br><figcaption>${
                  node.data.target.fields.description
                }</figcaption>`
                : ''
              }
            </div>
            `;
            // return `
            // <img src="${file.url}" alt="${file.fileName}" />
            // ${
            //   node.data.target.fields.description
            //     ? `<figcaption>${marked(
            //         node.data.target.fields.description
            //       )}</figcaption>`
            //     : ''
            // }
            // `;
        }
        if (file.contentType.startsWith('audio')) {
          const id = node.data.target.sys.id
          return `
            <audio controls preload="auto" data-asset-id="${
              file.url.split('/')[4]
            }" data-contentful-id="${id}">
                <source src="${file.url}" type="${
            file.contentType
          }" data-remote="https:${file.url}" data-local="${file.url}">
                <source src="https:${file.url}" type="${
            file.contentType
          }" data-fallback="true">
            </audio>`;
        }
        if (file.contentType.startsWith('application/pdf')) {
          const title = node.data.target.fields.title || '';
          const description = node.data.target.fields.description || '';
          return `
            <a href="${file.url}" target="_blank" data-remote="https:${
            file.url
          }" data-local="${file.url}">${
            title || description || 'PDF Document'
          }</a>`;
        }
        if (file.contentType.startsWith('video')) {
          const src = node.data.target.fields.src
            ? node.data.target.fields.src.fields.file.url
            : '';
          const poster = node.data.target.fields.coverImage
            ? node.data.target.fields.coverImage.fields.file.url
            : 'poster';
          const subtitleFiles = node.data.target.fields.subtitleFiles || [];
          const subtitleTracks = subtitleFiles.map((f, index) => {
            return `<track label="${
              f.fields.title
            }" kind="subtitles" srclang="en" src="https:${f.fields.file.url}" ${
              index === 0 ? 'default' : ''
            }>`;
          });

          return `
            <div class="video-container poptential_image_container">
              ${
                node.data.target.fields.displayTitle2
                  ? `<p class="video-title">${documentToHtmlString(
                      node.data.target.fields.displayTitle2
                    )}</p>`
                  : ''
              }
                <video width="640" height="360" controls="true" controlsList="nodownload" poster="${poster}" data-asset-id="${
            src.split('/')[4]
          }" data-remote="https:${poster}"  data-local="${poster}" crossorigin=anonymous>
                    <source src="${src}" type="video/mp4" data-remote="https:${src}" data-local="${src}" >
                    <source src="https:${src}" type="video/mp4" data-fallback="true">
                    ${subtitleTracks.join(' ')}
                </video>
            </div>
          `;
        }
        if (file.url) {
          return `
              <a href="${file.url}" target="_blank">${file.fileName}</a>
            `;
        }
        return '';
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let html = '';
        let surveyType = '';
        surveyType = '14I2QtNxDznew5vkt8cJBW';
        const surveyAnswer = '4jsaYgbzwPXxVpqJhgeLH7';
        const nodeType = node.data.target && node.data.target.sys && node.data.target.sys.contentType && node.data.target.sys.contentType
            ? node.data.target.sys.contentType.sys.id : null;
        if (!nodeType) {
          return '';
        }
        if (nodeType === 'infoBox') {
          const body = node.data.target.fields.body;
          const infoBoxType = node.data.target.fields.type;
          const title = node.data.target.fields.customTitle;
          const getTitle = (defaultTitle = infoBoxType) =>
            title ? title : defaultTitle;
          // surveyType = "14I2QtNxDznew5vkt8cJBW";
          // const surveyAnswer = "4jsaYgbzwPXxVpqJhgeLH7";

          if (infoBoxType === 'Takeaway') {
            return `
              <div class="takeaway">
                <h3 class="takeaway-header">${getTitle('Takeaway:')}</h3>
                <div class="takeaway-content">
                  ${documentToHtmlString(
                    body,
                    contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                  )}
                </div>
              </div>
            `;
          }
          if (infoBoxType === 'Lesson Complete') {
            return `
              <div class="finished">
                <h3 class="finished-header">${getTitle()}</h3>
                <div class="finished-content">
                  ${documentToHtmlString(
                    body,
                    contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                  )}
                </div>
              </div>
            `;
          }
          if (infoBoxType === 'Question') {
            return `
              <div class="question">
                <div class="soft-question-container">
                  <h3 class="question-header">${getTitle(
                    'Are you getting it?'
                  )}</h3>
                  <div class="question-content question-block">
                    ${documentToHtmlString(body, contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                    )}
                  </div>
                </div>
              </div>
            `;
          }
          return `
          <blockquote>
             ${documentToHtmlString(
               body,
               contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
             )}
          </blockquote>
          `;
        }

        if (nodeType == 'math') {
          return `<div class="formula-entry">${node.data.target.fields.math}</div>`;
        }

        if (nodeType == 'header') {
          if (node.data.target.fields.headerType == 'unit') {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p style="font-family:'Noto Serif JP';font-weight:'bold';font-size:32px;">${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header unit">
                <div class="poptential-header__image">
                  <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                    <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                  </div>
                </div>
                <div class="poptential-header__gradient">
                  <p>${node.data.target.fields.headerQuote}</p>
                </div>
              </div>`;
            }
          } else {
            if (node.data.target.fields.headerImage) {
              return `<div class="poptential-header" style="background:url('${node.data.target.fields.headerImage.fields.file.url}')">
                <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                  <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            } else {
              return `<div class="poptential-header">
                <div class="poptential-header__title" style="border:4px solid ${node.data.target.fields.textAndBorderColor}">
                <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Mukta';font-weight:'bold';font-size:32px;">${node.data.target.fields.moduleNumber}</h2>
                <h2 style="color:${node.data.target.fields.textAndBorderColor};font-family:'Corben';font-style: italic;font-weight:700;font-size:36px;">${node.data.target.fields.moduleName}</h2>
                </div>
              </div>`;
            }
          }
        }

        if (nodeType == 'columnLayout') {
          const body1 = node.data.target.fields.column1;
          const body2 = node.data.target.fields.column2;
          const body3 = node.data.target.fields.column3;
          return `
            <div class="flex-container">
              <div style="display:${
                body1 ? 'block' : 'none'
              }">${documentToHtmlString(
            body1,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body2 ? 'block' : 'none'
              }">${documentToHtmlString(
            body2,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
              <div style="display:${
                body3 ? 'block' : 'none'
              }">${documentToHtmlString(
            body3,
            contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
          )}</div>
            </div>
          `;
        }

        if (nodeType === 'centeredContent') {
          const body = node.data.target.fields.body;
          return `
            <div class="centered-div-block">
                ${documentToHtmlString(
                  body,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                )}
            </div>
            `;
        }

        // This purposefully does not close <div> tag
        if (nodeType === 'gabeTest') {
          return `
            <div style="background-color: lightblue;">Start Blue Div</div>
              <div style="background-color: #333333;">Content div
          `;
        }

        if (nodeType === 'gabeTestEnd') {
          return `
            </div>
          `;
        }

        if (nodeType === 'multipleChoiceQuestion' || nodeType === surveyType) {
          const question = node.data.target.fields.question;
          const answerDataFromContentful = get(node, 'data.target.fields.answers', []);
          const answersSortedIfMCQ = nodeType === 'multipleChoiceQuestion' ? sortBy(answerDataFromContentful, () => Math.random() - 0.5) : answerDataFromContentful;
          const questionId = node.data.target.sys.id;
          const answersElements = answersSortedIfMCQ.map((a) => {
              return `<li>
                          <input type="radio" 
                          name="${questionId}" 
                          ${
                            isMobileBundle
                              ? `data-quiz-choice-correct="${a.fields.isAnswerCorrect}"`
                              : ''
                          } 
                          data-quiz-choiceuuid="${a.sys.id}" 
                          id="${a.sys.id}"
                          class="multiple-choice-quiz-choices" 
                          value="${a.fields.answer}"> 
                          <label for="${a.sys.id}">${a.fields.answer}</label>
                          <div class="multiple-choice-explanation">${
                            a.fields.explanation || ''
                          }</div>
                      </li>`;
            });

          if (nodeType === 'multipleChoiceQuestion') {
            html = `
              <div class="ebook-quiz ebook-multiple-choice-quiz" data-quizuuid="${questionId}">
                <p class="quizQuestion">${question}</p>
                <ul>
                   ${answersElements.join('\n')} 
                </ul>
                <button class="soft-spoken-mc-submit">Submit Answer</button>
              </div>
          `;
          }

          if (nodeType === surveyType) {
            html = `
              <div class="question ebook-quiz" data-quizuuid="${questionId}">
                <div class="soft-question-container ebook-multiple-choice-quiz">
                  <p class="quizQuestion">${question}</p>
                  <ul>
                    ${answersElements.join('\n')} 
                  </ul>
                  <button class="soft-spoken-mc-submit ebook-quiz-submit">Submit Answer</button>
                </div>
              </div>
          `;
          }
        } else if (nodeType === 'shortAnswerQuestion') {
          const question = node.data.target.fields.question;
          const questionId = node.data.target.sys.id;
          const maxScore = node.data.target.fields.maxScore;
          html = `
                <div class="ebook-short-answer-quiz" data-quizuuid="${questionId}">
                ${
                  maxScore
                    ? `<p class="sa-quiz-points" style="font-weight:bold;font-size:1rem;margin-bottom:5px;"><span class="student-score">--</span>/${maxScore} points</p>`
                    : ''
                }
                  <p style="margin-top:0;" class="quizQuestion">${question}</p>
                  <textarea id="shortAnswerAnswer" rows="5"></textarea>
                  <button class="ebook-quiz-submit" id="btnSumitShortAnswer">Submit Answer</button>
                </div>
          `;
        } else if (nodeType === 'video') {
          const src = node.data.target.fields.src
            ? node.data.target.fields.src.fields.file
              ? node.data.target.fields.src.fields.file.url
              : ''
            : '';
          const poster = node.data.target.fields.coverImage
            ? node.data.target.fields.coverImage.fields.file.url
            : 'poster';
          const subtitleFiles = node.data.target.fields.subtitleFiles || [];
          const subtitleTracks = subtitleFiles.map((file, index) => {
            if (file.fields) {
              return `<track label="${
                file.fields.title
              }" kind="subtitles" srclang="en" src="https:${
                file.fields.file.url
              }" ${index === 0 ? 'default' : ''}>`;
            }
          });

          const id = node.data.target.sys.id

          html = `
            <div class="video-container poptential_image_container">
              ${
                node.data.target.fields.displayTitle2
                  ? `<p class="video-title">${documentToHtmlString(
                      node.data.target.fields.displayTitle2
                    )}</p>`
                  : ''
              }
                <video width="640" height="360" controls="true" controlsList="nodownload" poster="${poster}" data-asset-id="${
            src.split('/')[4]
          }" data-remote="https:${poster}" data-local="${poster}" data-contentful-id="${id}" crossorigin=anonymous>
                    <source src="${src}" type="video/mp4" data-remote="https:${src}" data-local="${src}">
                    <source src="https:${src}" type="video/mp4" data-fallback="true">
                    ${subtitleTracks.join(' ')}
                </video>
            </div>
          `;
        } else if (nodeType === 'embeddedvideo') {
          const src = node.data.target.fields.videoLink || '';
          const [site, id] = extractVideoLinkTypes(src);
          html = '';
          if (!site || !id) {
            return html;
          }
          if (site === 'vimeo') {
            html = `
    <iframe title="vimeo-player" src="https://player.vimeo.com/video/${id}" width="640" height="360" frameborder="0" allowfullscreen></iframe>
              `;
          } else if (site === 'youtube') {
            html = `
              <iframe id="ytplayer" type="text/html" width="640" height="360" src="https://www.youtube.com/embed/${id}" frameborder="0"></iframe>
              `;
          }
        } else if (nodeType === 'teacherResourcesBlock') {
          const teacherBlocks = node.data.target.fields.teacherBlocks;
          const showTeachersBlock = isTeacherUserType
            ? `${documentToHtmlString(
                teacherBlocks,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
              )}`
            : '';
          html = `
            <div>
              ${showTeachersBlock}
            </div>
           `;
        } else if (nodeType === 'teacherContent' && isTeacherUserType) {
          const content = node.data.target.fields.content;
          let url;
          if (node.data.target.fields.attachment) {
            url = node.data.target.fields.attachment.fields.file.url;
          }
          let iconSource =
            TEACHER_ICON_MAP[node.data.target.fields.teacherContentType];
          if (!iconSource) {
            iconSource = '/icons/default-icon.svg';
          }
          const subtitleVisible = node.data.target.fields.subtitle
            ? `<div class="teacher-content__header__subtitle">${node.data.target.fields.subtitle}</div>`
            : '';
          const showDownloadButton = url
            ? `
            <div style="display: flex; flex-direction: row; background-color: #007790; align-items: center; border-radius: 4px; width: 150px">
              <a href="${url}" style="padding: 9px; color: white; font-size: 14px; text-decoration: none" download>DOWNLOAD</a>
              <img style="width: 50px; height: 40px; background-color: #B0CFD6; margin: 0; border-radius: 0 4px 4px 0" src="/icons/arrow-circle-down.svg"/>
            </div>`
            : '';
          html = `
            <div class="teacher-content">
              <div class="teacher-content__header">
                <div class="teacher-content__header__title">
                  <img src="${iconSource}"/>
                  <p>${node.data.target.fields.teacherContentType}</p>
                </div>
                ${subtitleVisible}
              </div>
                <hr/>
              <div class="teacher-content__content">
                ${documentToHtmlString(
                  content,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                )}
              </div>
              <div style="padding-left: 10px">
                ${showDownloadButton}
              </div>
            </div>
           `;
        } else if (nodeType === 'donorContent') {
          const content = node.data.target.fields.content;
          let url;
          if (node.data.target.fields.attachment) {
            url = node.data.target.fields.attachment.fields.file.url;
          }
          let iconSource =
            DONOR_ICON_MAP[node.data.target.fields.donorContentType];
          if (!iconSource) {
            iconSource = '/icons/default-icon.svg';
          }
          const subtitleVisible = node.data.target.fields.subtitle
            ? `<div class="teacher-content__header__subtitle">${node.data.target.fields.subtitle}</div>`
            : '';
          const showDownloadButton = url
            ? `
              <div style="display: flex; flex-direction: row; background-color: #007790; align-items: center; border-radius: 4px; width: 150px">
                <a href="${url}" style="padding: 9px; color: white; font-size: 14px; text-decoration: none" download>DOWNLOAD</a>
                <img style="width: 50px; height: 40px; background-color: #B0CFD6; margin: 0; border-radius: 0 4px 4px 0" src="/icons/arrow-circle-down.svg"/>
              </div>`
            : '';
          html = `
              <div class="teacher-content">
                <div class="teacher-content__header">
                  <div class="teacher-content__header__title">
                    <img src="${iconSource}"/>
                    ${node.data.target.fields.donorContentType}
                  </div>
                  ${subtitleVisible}
                </div>
                  <hr/>
                <div class="teacher-content__content">
                  ${documentToHtmlString(
                    content,
                    contentfulToHtmlOptions(isMobileBundle, false, ebookStyle)
                  )}
                </div>
                <div style="padding-left: 10px">
                  ${showDownloadButton}
                </div>
              </div>
             `;
        } else if (nodeType === 'miniLesson') {
          const blockBody = node.data.target.fields.body;
          return ` 
            <div>
              ${documentToHtmlString(
                blockBody,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, false, ebookStyle)
              )}
            </div>
           `;
        } else if (nodeType === 'bellRinger') {
          const blockBody = node.data.target.fields.body;
          return ` 
            <div>
              ${documentToHtmlString(
                blockBody,
                contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, false, ebookStyle)
              )}
            </div>
           `;
        }
        return html;
      },
      [BLOCKS.QUOTE]: (node) => {
        const content = node.content
          ? node.content.map((n) => {
                return documentToHtmlString(n,
                  contentfulToHtmlOptions(isMobileBundle, isTeacherUserType, ebookStyle)
                );
              })
              .join('\n')
          : '';
        return `
          <div class="soft-quote-block">
            <div class="soft-quote-pre">
              ${`\n${content}`}
            </div>
          </div>
        `;
      }
      
    }
  }
}

export function extractVideoLinkTypes(link) {
  let site = null;
  let id = null;

  if (link.includes('vimeo.com/')) {
    site = 'vimeo';
    const hashIndex = link.indexOf('#');
    if (hashIndex > 0) {
      link = link.substring(0, hashIndex);
    }
    const parts = link.split('/');
    id = parts[parts.length - 1];
  } else if (link.includes('youtube.com/')) {
    site = 'youtube';
    const paramIndex = link.indexOf('?');
    link = link.substring(paramIndex + 1);
    const parts = link.split('&');
    for (let i = 0; i < parts.length; i += 1) {
      if (parts[i].startsWith('v=')) {
        id = parts[i].substring(2);
        break;
      }
    }
  }

  return [site, id];
}
