import React, {useState, useEffect} from 'react'
import StateDropdown from './StateDropdown'

const SchoolSearch = (props) => {
    const [state, setState] = useState('')
    const [schools, setSchools] = useState([])

    useEffect(() => {
        console.log(`state changed to ${state}`)
    }, [state])


    function closeModal() {
        $('#schoolSearchModal').css({
            display: 'none'
        });
    }

    function closeAndReload() {
        closeModal()
        window.location.reload()
    }

    function handleNameChange(e) {
        searchSchools(e.target.value)
    }

    function searchSchools(name) {
        fetch(`${window.location.origin}/schools/search/${state}/${name}`)
            .then(response => response.json())
            .then(obj => {
                setSchools(obj.schools)
            })
        
    }

    function setSchool(e, id) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id
            })
          }).then(response => response.json())
            .then(obj => {
                if (obj.success) {
                    console.log("added school")
                    closeAndReload()
                } else {
                    alert("Failed to add school")
                }
            })
    }

    function submitHomeschool(e) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add_homeschool`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                state: state
            })
          }).then(response => response.json())
            .then(obj => {
                if (obj.success) {
                    console.log("added school")
                    closeAndReload()
                } else {
                    alert("Failed to add school")
                }
            })
    }

    function submitOtherSchool(e) {
        e.preventDefault()
        fetch(`${window.location.origin}/schools/add_other_school`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                state: state
            })
          }).then(response => response.json())
            .then(obj => {
                if (obj.success) {
                    console.log("added school")
                    closeAndReload()
                } else {
                    alert("Failed to add school")
                }
            })
    }

    function displaySearch() {
        return (state.length === 2 )
    }

    function displayExit() {
        return (!props.display)
    }

    return(
          <div className="modal fade in" id="schoolSearchModal" style={{display: props.display ? "block" : "none"}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title center">Welcome!</h4>
                </div>
                <div className="modal-body">
                  <p>
                    Please tell us a little more about yourself
                  </p>
                  <div className="row">
                    <div className="col-md-10 col-md-offset-1">
                      <form>
                        <div className="form-group">
                          <label className="control-label">First, select your state:</label>
                          <StateDropdown setState={setState}/>
                        </div>
                        { displaySearch() &&
                            <div>
                                <div className="form-group">
                                  <label className="control-label">Then, search for your school:</label>
                                  <input type="text" className="form-control input-lg" placeholder="Enter the name of your school" onChange={handleNameChange} />
                                </div>
                                {schools.map((school) =>
                                    <button className="btn btn-primary" key={school.id} onClick={(e) => setSchool(e, school.id)}><strong>{school.name}</strong> ({school.city}) →</button>
                                )}
                                <button className="btn btn-secondary" onClick={(e) => submitHomeschool(e)}><strong>I am homeschooling →</strong></button>
                                <button className="btn btn-secondary" onClick={(e) => submitOtherSchool(e)}><strong>Other (or school not found) →</strong></button>
                            </div>
                        }
                      </form>
                    </div>
                  </div>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
    )

}

export default SchoolSearch